import React from "react"
import { graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class ContactPage extends React.Component {

  render() {

    const contacts = this.props.data;
    const intl = this.props.pageContext.intl;

    return (

      <Layout>
        <SEO lang={intl.language} title={intl.messages.contactsSeoTitle} description={intl.messages.contactsSeoDescription} />

        <div className="contacts">
          <div className="container">
            <div className="contacts-col-left">
              <div className="contacts-img">
                <Img fluid={contacts.contacts_person_1.childImageSharp.fluid} alt={intl.messages.aboutUsPersonName2} />
                <h3><FormattedMessage id="aboutUsPersonName2" /></h3>
                <p className="contacts-phone"><a href="tel:+380672340275">+38 (067) 234 02 75</a></p>
                <p className="contacts-email"><a href="mailto:oldesign434@gmail.com">oldesign434@gmail.com</a></p>
              </div>
            </div>
            <div className="contacts-col-right">
              <div className="contacts-img">
                <Img fluid={contacts.contacts_person_2.childImageSharp.fluid} alt={intl.messages.aboutUsPersonName1} />
                <h3><FormattedMessage id="aboutUsPersonName1" /></h3>
                <p className="contacts-phone"><a href="tel:+380675087910">+38 (067) 508 79 10</a></p>
                <p className="contacts-email"><a href="mailto:oldesign434@gmail.com">oldesign434@gmail.com</a></p>
              </div>
            </div>
          </div>

        </div>

      </Layout>
    )
  }
}

export default injectIntl(ContactPage)

export const pageQuery = graphql`
  query {
    contacts_person_1: file(relativePath: { eq: "about-us/olya-1.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contacts_person_2: file(relativePath: { eq: "about-us/olya-2.jpg" }) {
      childImageSharp {
        fluid(quality:75, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`